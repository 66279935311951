<template>
  <div class="spikes"></div>
  <div class="footer">
    <div class="img">
      <img src="../../assets/compass.svg" class="w-full h-full" alt="" />
    </div>
    <p>Copyright&copy; BuyersCompass.com 2024</p>
  </div>
</template>
<script>
const mobileMQL = window.matchMedia("(max-width: 767px)");
export default {
  data() {
    return {
      mobile: false,
    };
  },
  methods: {
    handleResize() {
      this.mobile = mobileMQL.matches;
      if (mobileMQL.matches) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    },
  },
  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.spikes {
  position: relative;
}

.spikes::after {
  content: "";
  position: absolute;
  right: 0;
  width: 100%;
  height: 30px;
  z-index: 2;
  background-image: linear-gradient(135deg, #fff 25%, transparent 25%),
    linear-gradient(225deg, #fff 25%, transparent 25%);
  background-size: 30px 30px;
}

.footer {
  height: 240px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #f5f5f5;
  overflow: hidden;
  position: relative;
}

@media (max-width: 767px) {
  .footer {
    flex-direction: column;
    height: 155px;
  }
}
p {
  font-size: 18px;
}
@media (max-width: 767px) {
  p {
    position: absolute;
    top: 110px;
    font-size: 14px;
  }
}
.footer .img {
  width: 350px;
  height: 300px;
}
@media (max-width: 767px) {
  .footer .img {
    width: 180px;
    height: 150px;
  }
}
</style>
