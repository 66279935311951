import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import HomeView from '@/views/Home.vue'

import { useToast } from 'vue-toast-notification';
const toast = useToast();

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/Contact.vue')
  },
  {
    path: '/plans',
    name: 'plans',
    component: () => import('@/views/PlansPricing.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('@/views/auth/Signup.vue'),
    meta: { hideHeader: true }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: { hideHeader: true }
  },
  {
    path: '/auth/forget',
    name: 'forgetPassword',
    component: () => import('@/views/auth/ForgetPassword.vue'),
    meta: { hideHeader: true }
  },
  {
    path: '/password-reset',
    name: 'restPassword',
    component: () => import('@/views/auth/restPassword.vue'),
    meta: { hideHeader: true }
  },
  {
    path: '/myWork',
    name: 'myWork',
    component: () => import('@/views/MyWork.vue'),
    meta: { requiresAuth: true, requiresSubscription: true }
  },
  {
    path: '/commodities',
    name: 'commodities',
    component: () => import('@/views/Commodities.vue'),
    meta: { requiresAuth: true, requiresSubscription: true },
  },
  {
    path: "/commodities/:id",
    name: "commodity",
    component: () => import('@/views/Commodity.vue'),
    meta: { requiresAuth: true, requiresSubscription: true }
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import('@/views/Dashboard.vue'),
    meta: { requiresAuth: true, requiresSubscription: true }
  },
  {
    path: "/priceAlert",
    name: "priceAlert",
    component: () => import('@/views/PriceAlert.vue'),
    meta: { requiresAuth: true, requiresSubscription: true },
  },
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    component: () => import('@/views/auth/VerifyEmail.vue'),
  },
  {
    path: '/verification',
    name: 'verification',
    component: () => import('@/views/auth/ResendVerifyEmail.vue'),
    meta: { hideHeader: true }
  },
  {
    path: '/subscription/status',
    component: () => import('@/views/SubscriptionStatus.vue'),
    meta: { hideHeader: true },
    children: [
      {
        path: "success",
        name: "SubscriptionSuccess",
        component: () => import('@/views/SubscriptionSuccess.vue'),
        meta: { hideHeader: true }
      },
      {
        path: "fail",
        name: "SubscriptionFail",
        component: () => import('@/views/SubscriptionFail.vue'),
        meta: { hideHeader: true }
      }
    ],
  },
  {
    path: '/stripe/:id',
    name: 'stripe',
    component: () => import('@/views/paymentView.vue'),
    meta: { hideHeader: true }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/404.vue'),
    meta: { hideHeader: true }
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // Check if the user is authenticated and verified
  const isLoggedIn = store.getters.isAuthenticated;
  //check if the user us subscribed
  const isSubscribed = store.getters.isSubscribed;

  if (to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) {

    toast.open({
      message: 'You need to log in to access this page.',
      type: 'error',
      duration: 5000,
      position: 'top'
    });

    next({ name: 'login' });
  }
  // If the route requires a subscription and the user is not subscribed
  else if (to.matched.some(record => record.meta.requiresSubscription) && !isSubscribed) {

    toast.open({
      message: 'Please subscribe to a plan to access this page.',
      type: 'warning',
      duration: 5000,
      position: 'top'
    });

    next({ name: 'plans' });
  } else {
    next();
  }
});

export default router
